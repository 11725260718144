import logo from './images/Logo.png';
import './App.css';

function App() {
  return (
    <div className="header-main">
      <div className="header-main__logo">
         <a href="https://strikeprice.in/" className="custom-logo-link" rel="home" title="StrikePrice"
              aria-label="Go to StrikePrice homepage">
              <img src={logo} width="571" height="73" className="custom-logo img-fluid" alt="StrikePrice Logo"/>
          </a>
    </div>
  </div>  
  );
}

export default App;